import React, { useEffect, useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Link, useLoaderData } from 'react-router-dom';
import AxiosInstance from 'src/components/Axios';
import { useNavigate } from "react-router-dom";
import { CForm, CFormLabel, CFormInput, CButton } from '@coreui/react';
import AsyncSelect from 'react-select/async';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyToastify from 'src/components/myComponents/MyToactify';

const urlApi = '/accounts';
const urlApi_2 = '/agreements';
const urlRouter = '/accounts';
const tokenStr = localStorage.getItem('user');
const header = { headers: { "Authorization": `Bearer ${tokenStr}`, 'accept': 'application/json' } };


const AccountsFormComponents = ({ data, type, setShowModalCreateAccount, setNewAccountModal, setNewAgreementModal, setIsUpdateOrder, allowUpdateOrder }) => {
  // console.log('data', data);

  const navigate = useNavigate();
  const [name, setName] = useState((data != undefined) ? data.name : '');
  const [description, setDescription] = useState((data != undefined) ? data.description : '');
  // const [edrpou, setEdrpou] = useState((data != undefined) ? data.edrpou : '');
  const [phone, setPhone] = useState((data != undefined) ? data.phone : '');
  const [user, setUser] = useState('');
  const [manager, setManager] = useState('');
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedManager, setSelectedManager] = useState(null);
  // Викликає popup з власного компонента
  const popup = MyToastify();
  // Текст для popup
  const popupTextPostSuccess = "Контрагента створено";
  const popupTextPutSuccess = "Контрагента оновлено";

  const getUserName = (user) => {
    let fn = (user.firstName != undefined) ? `${user.firstName} ` : '';
    let ln = (user.lastName != undefined) ? `${user.lastName} ` : '';
    let un = (user.username != undefined) ? ` (${user.username})` : '';
    return `${fn}${ln}${un}`;
  };

  if (data != undefined && data.user != undefined && user == '') {
    setUser(`/api/users/${data.user.id}`);
    setSelectedOption({
      label: getUserName(data.user),
      value: data.user.id,
    });
  }

  if (data != undefined && data.manager != undefined && manager == '') {
    setManager(`/api/users/${data.manager.id}`);
    setSelectedManager({
      label: getUserName(data.manager),
      value: data.manager.id,
    });
  }

  // const [ipn, setIpn] = useState((data != undefined) ? data.ipn : '');
  // const [type, setType] = useState((data != undefined) ? data.type : '');
  // const [parent, setParent] = useState((data != undefined) ? data.parent : '');
  // const [director, setDirector] = useState((data != undefined) ? data.director : '');
  // const [code1c, setCode1c] = useState((data != undefined) ? data.code1c : '');
  // const [manager, setManager] = useState((data != undefined) ? data.manager : '');
  const editorRef = useRef(null);
  // Ключ для Editor
  const apiKey = process.env.REACT_APP_TINYMCE_API_KEY;

  // Для запобігання оновлення замовлення при створенні контрагента, коли контрагент створюється з сторінки замовлення
  useEffect(() => {
    if (setIsUpdateOrder != undefined) {
      setIsUpdateOrder(false);
    }
  }, []);

  // Функція для створення контрагента
  const createAccount = (data_response) => {
    // console.log('data_response', data_response);
    AxiosInstance.post(`${urlApi}`, data_response, header).then((response) => {
      if (response.status === 201) {
        // Popup про успішні зміни
        popup.toastifySuccess(popupTextPostSuccess);

        if (type != undefined && type === "create") {
          setNewAccountModal(
            {
              name: response.data.name,
              id: response.data.id
            },
          );
          // Створити договір
          createAgreement(data_response, response.data.id);

          // Для запобігання оновлення замовлення при створенні контрагента, коли контрагент створюється з сторінки замовлення
          allowUpdateOrder();
        } else {
          return navigate(urlRouter);
        };
      };
    });
  };

  // Функція для оновлення контрагента
  const updateAccount = (data_response) => {
    AxiosInstance.put(`${urlApi}/${data.id}`, data_response, header).then((response) => {
      if (response.status === 200) {
        // Popup про успішні зміни
        popup.toastifySuccess(popupTextPutSuccess);
        return navigate(urlRouter);
      }
    });
  };

  // Функція для створення договору
  const createAgreement = (data, accountId) => {
    const data_response = {
      name: `${data.name} (Каса)`,
      account: `/api/accounts/${accountId}`,
      typePayment: "kasa",
      // active: 'on',
    };

    // console.log('createAgreement data_response', data_response);
    AxiosInstance.post(`${urlApi_2}`, data_response, header).then((response) => {
      if (response.status === 201) {
        setNewAgreementModal({
          name: response.data.name,
          id: response.data.id,
          typePayment: response.data.typePayment
        });

        // Popup про успішні зміни
        // popup.toastifySuccess(popupTextPostSuccess);
        // alert("Закрити вікно додавання");
        setShowModalCreateAccount(false);
      } else {
        console.log('Помилка запиту');
      };
    });
  };

  const handleSubmit = event => {
    event.preventDefault();
    if (editorRef.current) {
      // console.log(editorRef.current.getContent());
      let data_response = {
        name: name,
        description: description,
        // edrpou: edrpou,
        phone: phone,
        // ipn: ipn,
        // type: type,
        // parent: parent,
        // director: director,
        // code1c: code1c,
        manager: manager,
        // user: user,
        active: 'on',
      };

      if (user != "") {
        data_response["user"] = user;
      }

      // Коли на моменнт створення замовлення не було створено контрагента
      if (type != undefined && type === "create") {
        // console.log("type === create");
        createAccount(data_response);
      } else if (data != undefined) {
        // console.log("put");
        updateAccount(data_response);
      } else {
        // console.log("post");
        createAccount(data_response);
      }
    }
  };

  const loadOptions = async (inputValue) => {
    return await AxiosInstance.get(`/users?firstName=${inputValue}&lastName=${inputValue}&username=${inputValue}`, { headers: { 'accept': 'application/json' } }).then((response) => {
      return response.data.map((result) => ({
        label: getUserName(result),
        value: result.id,
      }));
    });
  };

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    setUser(`/api/users/${selectedOption.value}`);
  };

  const handleChangeManagerr = (selectedOption) => {
    setSelectedManager(selectedOption);
    setManager(`/api/users/${selectedOption.value}`);
  };

  return (
    <>
      {/* Popup про успішні зміни (справа зверху) */}
      <ToastContainer />

      <CForm onSubmit={handleSubmit} className="mb-3">
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Назва*</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput1" value={name} onChange={(e) => setName(e.target.value)} autoComplete="off" required />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Користувач (Клієнт)</CFormLabel>
          <AsyncSelect
            cacheOptions
            loadOptions={loadOptions}
            defaultOptions
            value={selectedOption}
            onChange={handleChange}
            className="flex-grow-1"
          />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput2">Опис</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput2" value={description} onChange={(e) => setDescription(e.target.value)} autoComplete="off" />
        </div>
        {/* <div className="mb-3">
            <CFormLabel htmlFor="exampleFormControlInput3">edrpou</CFormLabel>
            <CFormInput type="text" id="exampleFormControlInput3" value={edrpou} onChange={(e) => setEdrpou(e.target.value)} autoComplete="off" />
          </div> */}
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput4">Телефон*</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput4" value={phone} onChange={(e) => setPhone(e.target.value)} autoComplete="off" required />
        </div>
        {/* <div className="mb-3">
            <CFormLabel htmlFor="exampleFormControlInput5">ipn</CFormLabel>
            <CFormInput type="text" id="exampleFormControlInput5" value={ipn} onChange={(e) => setIpn(e.target.value)} autoComplete="off" />
          </div> */}
        {/* <div className="mb-3">
            <CFormLabel htmlFor="exampleFormControlInput6">Тип</CFormLabel>
            <CFormInput type="text" id="exampleFormControlInput6" value={type} onChange={(e) => setType(e.target.value)} autoComplete="off" />
          </div> */}
        {/* <div className="mb-3">
            <CFormLabel htmlFor="exampleFormControlInput7">parent</CFormLabel>
            <CFormInput type="text" id="exampleFormControlInput7" value={parent} onChange={(e) => setParent(e.target.value)} autoComplete="off" />
          </div> */}
        {/* <div className="mb-3">
            <CFormLabel htmlFor="exampleFormControlInput8">director</CFormLabel>
            <CFormInput type="text" id="exampleFormControlInput4" value={director} onChange={(e) => setDirector(e.target.value)} autoComplete="off" />
          </div> */}
        {/* <div className="mb-3">
            <CFormLabel htmlFor="exampleFormControlInput8">code1c</CFormLabel>
            <CFormInput type="text" id="exampleFormControlInput6" value={code1c} onChange={(e) => setCode1c(e.target.value)} autoComplete="off" />
          </div> */}
        {/* <div className="mb-3">
            <CFormLabel htmlFor="exampleFormControlInput9">Менеджер</CFormLabel>
            <CFormInput type="text" id="exampleFormControlInput9" value={manager} onChange={(e) => setManager(e.target.value)} autoComplete="off" />
          </div> */}
        <div className="mb-3" style={{ zIndex: "2", position: "relative" }}>

          <CFormLabel htmlFor="exampleFormControlInput1">Менеджер</CFormLabel>
          <AsyncSelect
            cacheOptions
            loadOptions={loadOptions}
            defaultOptions
            value={selectedManager}
            onChange={handleChangeManagerr}
            className="flex-grow-1"
            menuPortalTarget={document.body}
          />
        </div>

        <div className="mb-3" style={{ zIndex: "1", position: "relative" }}>

          <Editor
            apiKey={apiKey}
            onInit={(evt, editor) => editorRef.current = editor}
            initialValue={(data != undefined) ? data.content : ''}
            init={{
              height: 500,
              menubar: false,
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount'
              ],
              toolbar: 'undo redo | formatselect | ' +
                'bold italic backcolor | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | ' +
                'removeformat | help',
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
            }}
          />
        </div>

        <CButton type="submit" color="success" title="Створити контрагента">Створити</CButton>
        {
          (setShowModalCreateAccount != undefined) ?
            <CButton className="ms-3" color="secondary" onClick={() => setShowModalCreateAccount(false)} title="Закрити вікно для створення контрагента">Закрити</CButton>
            :
            <Link to={urlRouter} className="ms-3"><CButton type="submit" color="secondary" title="Повернутися на сторінку з списком контрагентів">Відміна</CButton></Link>
        }
      </CForm>
    </>
  );
}

export default AccountsFormComponents;