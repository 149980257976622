import React, { useState } from 'react';
import { CForm } from '@coreui/react';
import axios from "axios";
import AxiosInstance from 'src/components/Axios';
import { confirmAlert } from 'react-confirm-alert'; // Import

const tokenStr = localStorage.getItem('user');

// Вибрати головне зображення для продукту
const SelectMainImg = ({ product_id, media, UpdateMedia, setShowModalMedia, toastifySuccess, mainMedia }) => {
  // console.log("DeleteImgFrom media", media);

  const style = { height: '100px', width: 'auto', cursor: "pointer" };

  // Текст для popup
  const popupTextMediaSuccess = "Головне зображення змінено";

  // Закрити форму з всіма зображеннями і відкрити форму підтвердження зміни зображення
  const openSelectForm = (id) => {
    // Закрити модальне вікно
    setShowModalMedia(false);
    // Змінити головне зображення продукта
    selectImg(id);
  }

  // Змінити головне зображення продукта
  function selectImg(id) {
    confirmAlert({
      title: 'Ви впевнені що хочите зробити вибране зображення головним?',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Так',
          onClick: () => {
            const url = process.env.REACT_APP_SERVER_URL + '/api/products';
            const response = AxiosInstance.put(`${url}/${product_id}`, { mainMedia: `api/media_objects/${id}` }).then((response) => {
              console.log("response", response);
              if (response.status === 200) {
                // Запит на оновлення даних (зображень) продукту
                UpdateMedia();
                // Popup про успішні зміни
                toastifySuccess(popupTextMediaSuccess);
              }
            });
          }
        },
        {
          label: 'Ні',
          onClick: () => {
            // Відкрити модальне вікно
            setShowModalMedia(true);
            // return;
          }
        }
      ]
    });
  }

  return (
    <>
      {/* <CForm onSubmit={handleSubmit} noValidate validated={validated} > */}
      {/* <CForm noValidate validated={validated} > */}
      <div className='d-flex justify-content-center mt-3'>
        {
          (media != '') &&
          media.map(el => {
            const isMainMedia = (mainMedia != false && mainMedia != null && el.id === mainMedia.id) ? true : false;
            const imgStyle = { ...style, border: isMainMedia ? "2px solid green" : "none", cursor: isMainMedia ? "default" : "pointer" }
            return (
              <div key={el.id} className="me-2">
                <img
                  className="me-3"
                  style={imgStyle}
                  src={(media != '') ? process.env.REACT_APP_SERVER_URL + '' + el.contentUrl : ''}
                  onClick={() => openSelectForm(el.id)}
                />
              </div>
            )
          })
        }
      </div >
      {/* </CForm> */}
    </>
  );
}

export default SelectMainImg;